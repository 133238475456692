@import "node_modules/material-ui-with-sass/src/sass/scaffolding";

// Define a custom sass file to override any variables defined in scaffolding.scss
// @import "themes.scss";
.container{
    &>div{
        margin-top:8px;
    }
}

@import "node_modules/material-ui-with-sass/src/sass/components";