@import "../styles.scss";

.strategyItemContainer{
    text-align: center;
    & .itemValue{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.loadingContainer{
    display: flex;
    justify-content: center;
}

.chartToggleContainer{
    display: flex;
    justify-content: center;
}