.debug-info-dialog__json-container {
    min-width: 600px;
    min-height: 600px;
    padding: 0 24px 16px 24px;
    overflow: auto;
}

.debug-info-dialog__title-bar {
    display: flex;
    align-items: center;
}

.debug-info-dialog__title-bar__close-btn {
    margin-left: auto;
}

.details-dialog__appbar-container {
    flex-grow: 0;
    flex-shrink: 0;
}

.details-dialog__appbar-inner {
    align-items: center;
    display: flex;
    width: 100%;
}

.details-dialog__appbar-inner__close-btn {
    margin-left: auto;
}

.details-dialog__table-container {
    flex: 1;
}

.strategy-page-title-bar {
    display: flex;
    padding: 0 24px;
    width: 100%;
    align-items: center;
}

.strategy-id-search-box-container {
    margin-left: auto;
}